import "./contactus.css";
import Navbar from "../Navbar/navbar";
import Shapes from "../shapes/shapes";
import location from "../images/location.png";
import telephone from "../images/telephone.png";
import email from "../images/email.png";
import Contactform from "../ContactForm/contactform";
import Contactdetails from "../contactdetails/contactdetails";
import map from "../images/map.png";

function contactus() {
  return (
    <div className="contact_container">
      <div className="form_navbar">
        <Navbar />
      </div>
      <div className="form_map">
        <div className="form_contact">
          <Contactform />
        </div>
        <div className="map_contact">
          <a
            target="_blank"
            href="https://www.google.com/maps/dir//Vishal+and+Co.,+H.O+1,+Zone+6%2FB,+Birsanagar,+Jamshedpur,+Jharkhand+831019/@22.7877431,86.2404053,17z/data=!4m9!4m8!1m0!1m5!1m1!1s0x39f5e360510d905f:0x689021cdc9960eb2!2m2!1d86.241518!2d22.7880167!3e0"
          >
            <img src={map} />
          </a>
        </div>
      </div>
      <div className="contact_details">
        <Contactdetails />
      </div>
    </div>
  );
}

export default contactus;
