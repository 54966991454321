import "./Homepage.css";
import { useNavigate } from "react-router-dom";
import truck_final from "../images/truck6.png";
import Contactdetails from "../contactdetails/contactdetails";

function Homepage() {
  let navigate = useNavigate();

  return (
    <div
      style={{
        backgroundImage: "linear-gradient(#fe5819, white)",
        height: "15em",
        marginTop: "1em",
      }}
      className="home_container"
    >
      <div
        className="home_text"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <p
          style={{
            fontSize: "3em",
            width: "15em",
            textAlign: "center",
            marginTop: "1.2em",
          }}
        >
          Providing Experienced Drivers for transportation of new vehicles
        </p>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <span
          style={{
            fontSize: "1em",
            textAlign: "center",
            marginTop: "-2%",
          }}
        >
          Services that helps to realise your business goals.
        </span>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <img src={truck_final} style={{ width: "75%", zIndex: "-5" }} />
      </div>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <button
          style={{
            marginTop: "3em",
            width: "13em",
            height: "4em",
            border: "none",
            backgroundColor: "#fe5819",
            color: "white",
            borderRadius: "1em",
            cursor: "pointer",
          }}
          onClick={() => navigate("/requestaquote")}
        >
          <p className="button">Request a Quote</p>
        </button>
      </div>

      <div style={{ marginTop: "3em" }}>
        <hr style={{ width: "75%", borderColor: "black" }} />
      </div>
      <div
        className="stats_home"
        style={{ display: "flex", justifyContent: "center", columnGap: "10em" }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <p
            style={{ fontSize: "3em", textAlign: "center", fontWeight: "900" }}
          >
            4+
          </p>
          <span
            style={{
              textAlign: "center",
              marginTop: "-2em",
              fontSize: "1.5em",
            }}
          >
            Years of
          </span>
          <span
            style={{
              textAlign: "center",
              fontSize: "1.5em",
            }}
          >
            Experienced Drivers
          </span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <p
            style={{ fontSize: "3em", textAlign: "center", fontWeight: "900" }}
          >
            130+
          </p>
          <span
            style={{
              textAlign: "center",
              marginTop: "-2em",
              fontSize: "1.5em",
            }}
          >
            Drivers
          </span>
          <span style={{ textAlign: "center", fontSize: "1.5em" }}>
            Available
          </span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <p
            style={{ fontSize: "3em", textAlign: "center", fontWeight: "900" }}
          >
            24x7
          </p>
          <span
            style={{
              textAlign: "center",
              marginTop: "-2em",
              fontSize: "1.5em",
            }}
          >
            Tracking
          </span>
          <span style={{ textAlign: "center", fontSize: "1.5em" }}>
            Possible
          </span>
        </div>
      </div>
      <div style={{ marginTop: "3em" }}>
        <hr style={{ width: "75%", borderColor: "black" }} />
      </div>
      <div style={{ marginTop: "-10%" }}>
        <Contactdetails />
      </div>
    </div>
  );
}

export default Homepage;
