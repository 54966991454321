import "./contactform.css";

function contactform() {
  return (
    <div className="form_cont">
      <div className="form_head">
        <p className="head">Contact Form</p>
        <p className="support">
          Our friendly team would love to hear from you.
        </p>
      </div>
      <div className="form_deets">
        <input type="text" placeholder="Your Name" />
        <input type="text" placeholder="Your Email" />
        <input type="text" placeholder="Your Contact" />
        <textarea placeholder="Your Message" />
      </div>
      <div className="form_submit">
        <button type="submit">Submit</button>
      </div>
    </div>
  );
}

export default contactform;
