import "./requestquote.css";
import Navbar from "../Navbar/navbar";
import Shapes from "../shapes/shapes";
import truck from "../images/quoteimage.svg";
import Quoteform from "./quoteform";
import Contactdetails from "../contactdetails/contactdetails";

function requestquote() {
  return (
    <div style={{ marginBottom: "10%" }}>
      <div>
        <Navbar />
      </div>
      <div className="centre_container" style={{ display: "flex", gap: "7em" }}>
        <div className="left_quote">
          <div
            className="quote_header"
            style={{
              width: "100%",
              height: "20em",
              display: "flex",
              marginLeft: "5%",
              marginTop: "2%",
            }}
          >
            <div className="quote_text" style={{ width: "50%" }}>
              <p style={{}}>
                The platform for requesting transportation of vehicles across
                India.
              </p>
              <p style={{ fontSize: "1em", width: "20em" }}>
                Get drivers with 4+ years of experience with 24x7 tracking.
              </p>
            </div>
          </div>
          <div
            className="quote_form"
            style={{ marginLeft: "5%", width: "75%" }}
          >
            <Quoteform />
          </div>
        </div>
        <div className="right_quote">
          <div
            className="quote_image"
            style={{
              marginTop: "3em",
              overflow: "scroll",
              position: "sticky",
              top: "10em",
            }}
          >
            <img
              src={truck}
              style={{
                width: "80%",
              }}
            />
          </div>
        </div>
      </div>
      <div style={{ marginTop: "-7%" }}>
        <Contactdetails />
      </div>
    </div>
  );
}

export default requestquote;
