import Navbar from "./Navbar/navbar";
import Homepage from "./HomePage/Homepage";
import Shapes from "./shapes/shapes.js";

function App() {
  return (
    <div className="app">
      <Navbar />
      <Homepage />
    </div>
  );
}

export default App;
