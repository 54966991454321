import "./quoteform.css";

function quoteform() {
  return (
    <div className="quote_container">
      <div className="quote_name">
        <p>1. Please mention your name</p>
        <input
          type="text"
          style={{
            marginLeft: "1.2%",
            width: "20em",
            height: "2em",
            borderColor: "gray",
            border: "solid",
            borderRadius: "0.3em",
            paddingLeft: "0.6em",
          }}
        />
      </div>
      <div className="quote_number">
        <p>2. Please mention your contact number</p>
        <input
          type="tel"
          style={{
            marginLeft: "1.2%",
            width: "20em",
            height: "2em",
            borderColor: "gray",
            border: "solid",
            borderRadius: "0.3em",
            paddingLeft: "0.6em",
          }}
        />
      </div>
      <div className="quote_email">
        <p>3. Please mention your email</p>
        <input
          type="text"
          style={{
            marginLeft: "1.2%",
            width: "20em",
            height: "2em",
            borderColor: "gray",
            border: "solid",
            borderRadius: "0.3em",
            paddingLeft: "0.6em",
          }}
        />
      </div>
      <div className="quote_vehicle">
        <p>4. Please mention the type of Vehicle you want to be transported</p>
        <input
          type="text"
          style={{
            marginLeft: "1.2%",
            width: "20em",
            height: "2em",
            borderColor: "gray",
            border: "solid",
            borderRadius: "0.3em",
            paddingLeft: "0.6em",
          }}
        />
      </div>
      <div className="quote_location">
        <p>5. Please mention the location</p>
        <div className="input_location">
          <input
            type="text"
            style={{
              marginLeft: "1.2%",
              width: "20em",
              height: "2em",
              borderColor: "gray",
              border: "solid",
              borderRadius: "0.3em",
              paddingLeft: "0.6em",
            }}
            placeholder="From: "
          />
          <input
            type="text"
            style={{
              marginLeft: "1.2%",
              width: "20em",
              height: "2em",
              borderColor: "gray",
              border: "solid",
              borderRadius: "0.3em",
              paddingLeft: "0.6em",
              marginTop: "1em",
            }}
            placeholder="To: "
          />
        </div>
      </div>
      <div className="quote_details">
        <p>6. Please mention any other necessary details</p>
        <textarea
          type="text"
          style={{
            marginLeft: "1.2%",
            width: "22em",
            height: "10em",
            borderColor: "gray",
            border: "solid",
            borderRadius: "0.3em",
            paddingLeft: "0.6em",
          }}
        />
      </div>
      <div className="button_quote">
        <button
          type="submit"
          style={{
            marginLeft: "1%",
            marginTop: "3%",
            width: "10em",
            height: "4em",
            border: "none",
            backgroundColor: "#fe5819",
            color: "white",
            fontSize: "1em",
            borderRadius: "1em",
            fontWeight: "900",
            cursor: "pointer",
          }}
        >
          Submit
        </button>
      </div>
    </div>
  );
}

export default quoteform;
