import "./Navbar.css";
import logo from "../images/logo.png";
import { useNavigate } from "react-router-dom";

function App() {
  let navigate = useNavigate();

  return (
    <div className="app">
      <div className="app_container">
        <div className="logo_container">
          <img onClick={() => navigate("/")} src={logo} />
        </div>
        <div className="header_container">
          <span className="header_button" onClick={() => navigate("/")}>
            Home
          </span>
          <span className="header_button" onClick={() => navigate("/aboutus")}>
            About Us
          </span>
          <span
            className="header_button"
            onClick={() => navigate("/requestaquote")}
          >
            Request a Quote
          </span>
          <span
            className="header_button"
            onClick={() => navigate("/contactus")}
          >
            Contact Us
          </span>
        </div>
      </div>
    </div>
  );
}

export default App;
