import "./contactdetails.css";
import email from "../images/email1.png";
import location from "../images/location1.png";
import telephone from "../images/phone.png";

function contactdetails() {
  return (
    <div className="detail_container">
      <div className="detail_header">
        <p>Chat with Us</p>
      </div>
      <div
        className="details_contact"
        style={
          {
            // width: "100%",
            // display: "flex",
            // justifyContent: "center",
            // columnGap: "10em",
            // paddingBottom: "3em",
          }
        }
      >
        <div
          className="email"
          style={{
            width: "12em",
            height: "12em",
            // backgroundColor: "#FFF7F2",
            backgroundColor: "white",
            borderRadius: "1em",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            className="email_icon"
            style={{
              width: "2.3em",
              //   backgroundColor: "#fe5819",
              height: "2.3em",
              marginLeft: "1em",
              marginTop: "1em",
            }}
          >
            <img src={email} style={{ width: "100%", height: "100%" }} />
          </div>
          <div
            className="email_details"
            style={{
              marginLeft: "1em",
              fontSize: "0.8em",
              marginTop: "3em",
              textAlign: "center",
            }}
          >
            <p>Send us an Email.</p>
            <p style={{ fontWeight: "700", textAlign: "center" }}>
              vishal.kumar965@gmail.com
            </p>
          </div>
        </div>
        <div
          className="location"
          style={{
            width: "12em",
            height: "12em",
            // backgroundColor: "#FFF7F2",
            backgroundColor: "white",
            borderRadius: "1em",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            className="location_icon"
            style={{
              //   backgroundColor: "#fe5819",
              width: "2.3em",
              height: "2.3em",
              display: "flex",
              justifyContent: "Center",
              alignItems: "center",
              marginLeft: "1em",
              //   paddingTop: "1em",
              //   paddingBottom: "0.5em",
              marginTop: "1em",
              borderRadius: "0.3em",
            }}
          >
            <img src={location} style={{ width: "100%", height: "100%" }} />
          </div>
          <div
            className="location_details"
            style={{
              marginLeft: "1em",
              fontSize: "0.8em",
              marginTop: "3em",
              textAlign: "center",
            }}
          >
            <p style={{ textAlign: "center" }}>Visit Us.</p>
            <span style={{ fontWeight: "700" }}>House No. 1, Zone 6/B</span>
            <span
              style={{
                fontWeight: "700",
                marginLeft: "0.3em",
              }}
            >
              Birsanagar, Jamshedpur
            </span>
            <span
              style={{
                fontWeight: "700",
                marginLeft: "0.3em",
              }}
            >
              831091
            </span>
          </div>
        </div>
        <div
          className="phone"
          style={{
            width: "12em",
            height: "12em",
            // backgroundColor: "#FFF7F2",
            backgroundColor: "white",

            borderRadius: "1em",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            className="phone_icon"
            style={{
              //   backgroundColor: "#fe5819",
              width: "2.3em",
              height: "2.3em",
              display: "flex",
              justifyContent: "Center",
              alignItems: "center",
              marginLeft: "1em",
              marginTop: "1em",
              borderRadius: "0.3em",
            }}
          >
            <img src={telephone} style={{ width: "100%", height: "100%" }} />
          </div>
          <div
            className="location_details"
            style={{
              marginLeft: "1em",
              fontSize: "0.8em",
              marginTop: "3em",
              textAlign: "center",
            }}
          >
            <p>Call Us.</p>
            <p style={{ fontWeight: "700" }}>7004817497(Call)</p>
            <span style={{ fontWeight: "700" }}>8757576590 (Whatsapp)</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default contactdetails;
