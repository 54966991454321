import "./AboutUs.css";
import Navbar from "../Navbar/navbar";
import driver from "../images/truckdriver.jpeg";
import Contactdetails from "../contactdetails/contactdetails";

function AboutUs() {
  return (
    <div>
      <div className="Navbar">
        <Navbar />
      </div>
      <div
        className="text_top"
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "flex-end",
          marginTop: "5%",
          marginLeft: "5%",
        }}
      >
        <div
          className="main_text"
          style={{
            width: "50%",
          }}
        >
          <span style={{ width: "10em", fontWeight: "900" }}>
            We build bridges between
          </span>
          <span
            style={{
              width: "10em",
              fontWeight: "900",
              paddingLeft: "0.25em",
              color: "gray",
            }}
          >
            companies and customers!
          </span>
        </div>
        <div
          className="support_text"
          style={{ width: "50%", display: "flex", justifyContent: "center" }}
        >
          <span
            style={{
              width: "25em",
              color: "#fe5819",
              fontWeight: "700",
              marginLeft: "2em",
            }}
          >
            We provide experienced drivers for the transportation of new
            vehicles, with fast response and tracking of the vehicle.
          </span>
        </div>
      </div>
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "2em" }}
      >
        <img
          src={driver}
          style={{
            width: "100%",
            height: "35em",
            objectFit: "cover",
          }}
        />
      </div>
      <div style={{ marginTop: "5em" }}>
        <hr style={{ width: "75%", borderColor: "black" }} />
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
        }}
      >
        <div
          className="together"
          style={{
            display: "flex",
            width: "75%",
            marginTop: "2em",
          }}
        >
          <div
            style={{
              width: "50%",
              fontSize: "3em",
              fontWeight: "700",
            }}
          >
            <span>Together we are strong</span>
          </div>
          <div
            className="together_text"
            style={{ width: "75%", fontSize: "1.5em" }}
          >
            <span>
              Our crew is always getting bigger and better; Our aim is not only
              to provide the best service but a consistent one too.
            </span>
            <p style={{ fontSize: "0.8em", fontWeight: "300" }}>
              We strive to achieve utter professionalism with our clients along
              side giving them the best service possible. We have experienced
              drivers who take drug tests every other week to ensure peak
              physical and mental condition.
            </p>
          </div>
        </div>
      </div>
      <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
        <div
          style={{
            display: "flex",
            width: "75%",
            justifyContent: "space-evenly",
            marginTop: "3em",
          }}
        >
          <div style={{ width: "50%" }}>
            <span style={{ fontSize: "1.5em", fontWeight: "700" }}>
              Vishal Kumar
            </span>
            <br />
            <span>Proprietor</span>
          </div>
          <div style={{ width: "75%", fontSize: "2em" }}>
            <span>
              "Our goal is to build a network of professional drivers with the
              ability to provide fruitful and enduring relationships with
              customers."
            </span>
          </div>
        </div>
      </div>
      <div style={{ marginTop: "5em" }}>
        <hr style={{ width: "75%", borderColor: "black" }} />
      </div>
      <Contactdetails />
    </div>
  );
}

export default AboutUs;
